import React, { useState } from 'react'
import { accordianLeftData, accordianRightData } from '../constant';

const Accordian = () => {
    const[showSelected, setSelected] = useState(null);
    const[showRightSelected, setRightSelected] = useState(null);

    return(
        <section className="">
            <div className="columns is-multiline">
            <div  className=" column is-half accordion" id="accordionExample">
            {accordianLeftData.map((data, index) => {
                return (  
                    <div key={`left-${index}`} className="card">
                        <div className={"card-header level" + (showSelected === index ? " active-row" : "")} id="headingOne" >
                            <button className="btn btn-link is-size-4 has-text-weight-bold btn-block text-left level-left collapsed" type="button" data-toggle="collapse" 
                            onClick={() => setSelected(index)}
                            data-target={"#collapseOne"+index} aria-expanded="false" aria-controls="collapseOne">
                                {data.heading}
                                <img src={data.icon} alt="Placeholder" />
                            </button>
                            
                        </div>
                        <div id={"collapseOne"+index} className="collapse open-content" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div className="card-body">
                                <div className="accordion-content">
                                    <p className="is-size-5 pb-3 has-text-black">{data.content}</p>
                                    <p className="is-size-5  has-text-weight-bold has-text-black">{data.title}</p>
                                    <p className="is-size-5 has-text-black">{data.description}</p>
                                </div>
                                <div className="columns is-multiline accordion-card-footer">
                               
                                    {data.footerData.map((item, index) => {
                                    return (    
                                        <div className="column is-one-third accordion-footer">
                                            <img src={item.icon} alt="Placeholder"/>
                                            <p className="is-size-5 has-text-weight-bold">{item.title}</p>
                                        </div>
                                    );
                                    })}
                                
                                </div>
                            </div>
                        </div>
                    </div>
                 );
                })}   
         </div>
         <div  className=" column is-half accordion" id="accordionExampleTwo">
            {accordianRightData.map((data, index) => {
                return (  
                    <div key={`right-${index}`} className="card">
                        <div className={"card-header level" + (showRightSelected === index ? " active-row" : "")}id="headingTwo">
                            <button className="btn btn-link is-size-4 has-text-weight-bold btn-block level-left text-left collapsed" type="button" data-toggle="collapse"  onClick={() => setRightSelected(index)} 
                            data-target={"#collapseTwo"+index} aria-expanded="false" aria-controls="collapseTwo">
                                {data.heading}
                                <img src={data.icon} alt="Placeholder"/>
                            </button>
                            
                        </div>
                        
                            <div id={"collapseTwo"+index} className="collapse open-content" aria-labelledby="headingTwo" data-parent="#accordionExampleTwo">
                            <div className="card-body">
                                <div className="accordion-content">
                                    <p className="is-size-5 pb-3 has-text-black">{data.content}</p>
                                    <p className="is-size-5  has-text-weight-bold has-text-black">{data.title}</p>
                                    <p className="is-size-5 has-text-black">{data.description}</p>
                                </div>
                                <div className="columns is-multiline accordion-card-footer">
                               
                                    {data.footerData.map((item, index) => {
                                    return (    
                                        <div className="column is-one-third accordion-footer">
                                            <img src={item.icon} alt="Placeholder"/>
                                            <p className="is-size-5 has-text-weight-bold">{item.title}</p>
                                        </div>
                                    );
                                    })}
                                
                                </div>
                            </div>
                            </div>
                       
                    </div>
                 );
                })
            }   
         </div>
        </div>
      </section>
    );
}

export default Accordian;