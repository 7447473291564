import React from 'react'

const BenefitsOverview = ({BenefitsOverviewData}) => {
    const {title='',description='',benefitsData=[]}=BenefitsOverviewData
  return (
    <div className="container">
        <div className='infographic-wrp'>
            <div className='hero-text'>
                <h1>{title}</h1>
                <p class="subheader-size">{description}</p>
            </div>
            <div className='benefits-items'>
                {benefitsData.length>0 && benefitsData.map((item,index)=>{
                    return  <div className='benefits-item-box'>
                                <img src={item.image} alt={item.title}/>
                                <h5>{item.title}</h5>
                                <p>{item.description}</p>
                            </div>
                })}
            </div>
            {/* <div className='hero-image'>

                <img src={image} alt="" />
            </div> */}
        </div>
   </div>
  )
}

export default BenefitsOverview