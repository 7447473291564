import React,{useState} from 'react';
import { allwellHeader, navbarItems, registrationLinks,liteAppLinks } from '../constant';
import Logo from "./../myallwell_logo.svg"
import { NavLink,useLocation} from 'react-router-dom'

const Navbar = (props) => {
    const registationLink = process.env.NODE_ENV ==='production' ? registrationLinks['production'] : registrationLinks['default'];
    const liteAppLink = process.env.NODE_ENV ==='production' ? liteAppLinks['production'] : liteAppLinks['default'];
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const tabButtonClicked = () => {
        setIsOpen((toggle)=>!toggle)
    }
    return (
            <div className={props.className}>
                <nav className="navbar is-transparent">
                    <div className="navbar-brand">
                        <p>
                        <NavLink to='/'><img src={Logo} alt="all well logo"/></NavLink>  
                        </p>
                        <div className={`navbar-burger ${isOpen?'is-active':''}`} onClick={tabButtonClicked} data-target="navbarExampleTransparentExample">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>

                    <div id="navbarExampleTransparentExample" className={`navbar-menu ${isOpen?'is-active':''}`}>
                        <div className="navbar-start">
                            {navbarItems.map((data, index) => {
                                    return ( 
                                        <p className={`navbar-item has-text-weight-semibold is-size-6`}  key={index}>{
                                            data.target=='_blank' ?
                                            <a className={`${location.pathname==data.href?'active-link':''}`} href={data.href} target={data.target}>{data.title}</a>
                                            :
                                            <NavLink className={`${location.pathname==data.href?'active-link':''}`} to={data.href}>{data.title}</NavLink>
                                        }</p>
                                    );
                                })
                            }
                           <a href={liteAppLink} className="navbar-item has-text-weight-semibold is-size-6 register">Login</a>
                           {/* <a href={registationLink} className="navbar-item has-text-weight-semibold is-size-6 register">Register</a> */}
                        </div>
                    </div>
                </nav>
            </div>
            )
};

export default Navbar;
