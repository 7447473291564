import React from 'react';
import { cardsData } from '../constant';
import CardComponent from './cardComponent';

const HomePageCards = () => {
    return(
       <section className="section-cards">
            <div className="container container-padding">
                <div className="columns is-multiline image-cards">
                    {cardsData.map((data, index) => {
                        return (
                                <div key={index} className="column is-one-third ">
                                    {index === 0 ? 
                                    <div>
                                        <p className="has-text-left has-text-weight-bold main-heading">{data.heading}</p>
                                        <p className="info-heading has-text-left has-text-weight-semibold">{data.text}</p>
                                    </div>
                                        : <CardComponent data={data}/>
                                    }
                                </div>
                                );
                        })}
                </div>
            </div>
        </section>
    )
}

export default HomePageCards;

