import React,{useState,useEffect} from 'react';
import { allwellHeader, footerContent } from '../constant'
import closeIcon from "../images/close_modal.png"
import { useMyContext } from './contextStore';

const hasSectionBeenShown = localStorage.getItem('sectionShown');

const AllwellFooter = (props) => {
   
    const [showSection, setShowSection] = useState(false);
    const { fixedFooterShown, updateContextValue } = useMyContext();

    useEffect(()=>{
        
        console.log('hasSectionBeenShown', hasSectionBeenShown,)
        if (!hasSectionBeenShown && !fixedFooterShown) {
        console.log('hasSectionBeenShown1', )
        setShowSection(true);
        localStorage.setItem('sectionShown', 'true');
        }
 
     },[])
 
     const FixedContentClickHandler =()=>{
        updateContextValue(true)
        setShowSection(false)
     }

    return (<div className='footer-main-container'>
        <div className={`footer-div ${showSection?'show-footer-section':''}`}>
            <div className="container">
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <p>
                                <img src={allwellHeader.image} alt="Header" />
                            </p>
                            {/* <span>{footerContent.addline1}</span>
                            <p>{footerContent.addline2}</p> */}
                        </div>
                    </div>
                    {/* <div className="level-right">
                        <div className="level-item">
                            {navbarItems.map((data, index) => {
                                return ( 
                                    <p className="footer-item has-text-weight-semibold is-size-6" key={index}>{data}</p>
                                );
                            })}
                        </div>
                    </div> */}
                </div>
                <div className="footer-address has-text-left">
                    <span>{footerContent.addline1}</span><br /><br />
                    <span>{footerContent.addline2}</span><br />
                    <span>{footerContent.addline3}</span><br />
                    <span>{footerContent.addline4}</span><br />
                </div>
                <div className="footer-copyright">
                    <div >
                        {/* <span>{footerContent.contactText}<a href={`tel: +${footerContent.phone}`}>{footerContent.phone}</a></span><br/> */}
                        <span>{footerContent.emailText}<a href={`mailto:${footerContent.email}`}>{footerContent.email}</a></span>
                    </div>
                    <div className="footer_copyright_area">
                        <div className="footer-copyright">{footerContent.copyright}</div>
                        <span><a target="_black" href={'https://myallwell.org/AllWell_PrivacyPolicy.pdf'}>Privacy Policy</a></span> &nbsp;&nbsp;&nbsp;
                        <span><a target="_black" href={'https://myallwell.org/AllWell_TermsofService.pdf'}>Terms of Service</a></span>
                    </div>


                </div>
            </div>
        </div>
        {showSection && <div className='footer-fixed-section'>
            <div className='footer-close-icon' >
                <img src={closeIcon}  onClick={()=>FixedContentClickHandler()}/>
            </div>
           <div className='footer-fixed-content' dangerouslySetInnerHTML={{ __html: (footerContent.fixedFooterContent) }} />
        </div>}
    </div>

    )
};

export default AllwellFooter;
