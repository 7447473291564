// MyContext.js
import React, { createContext, useContext, useState } from 'react';

const MyContext = createContext();

export const useMyContext = () => {
  return useContext(MyContext);
};

export const MyContextProvider = ({ children }) => {
  const [fixedFooterShown, setFixedFooterShown] = useState(false);

  const updateContextValue = (newValue) => {
    setFixedFooterShown(newValue);
  };

  return (
    <MyContext.Provider value={{ fixedFooterShown, updateContextValue }}>
      {children}
    </MyContext.Provider>
  );
};
