import React from 'react'
import Navbar from "../components/Navbar"
import AllwellFooter from '../components/AllwellFooter';
import MembershipData from "../constant/MembershipData.json"
import Overview from '../components/Overview';

const Membership = () => {
    const {MembershipOverview={},OurBenefits={},AvibraEssential={},membershipData=[]}=MembershipData
    const {EssentialList={}}=AvibraEssential;
    return (

        <section className="section-home">
            <div className='container'>
                <Navbar className="modify-nav" />
            </div>
            <Overview MembershipOverview={MembershipOverview}/>
           
            <div className='container'>
                <div className='our-members-section'>
                    <div className='section-title'>
                        <h2>{OurBenefits.heading}</h2>
                    </div>
                    <div className='section-content'>
                        <p class="wider-text">{OurBenefits.description}</p>
                        <div class="types-of-industries-container">
                            <ul class="types-of-industries-list">
                                {OurBenefits.BenefitList.map((item,index)=>{
                                 return <li class="has-span-icon" key={index}><span>{item}</span></li>
                                })}
                               
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='basic-membership-section'>
                <div className='container'>

                    <div className='membership-wrp'>
                        <div class="section-title">
                            <h2>{AvibraEssential.heading}</h2>
                        </div>
                        <div className='top-text-details'>
                            <div className='row'>
                                <div className='col-lg-5'>
                                    <div className='left-side-text'>
                                        <p>{AvibraEssential.description}</p>
                                    </div>
                                </div>
                                <div className='col-lg-7'>
                                    <div className='right-side-text'>
                                        <h3>{AvibraEssential.btnAboveText}</h3>
                                        {/* <a href={AvibraEssential.btnLink} class="btn-class" target='_blank'>{AvibraEssential.btnText}</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        <div className='second-section-title'>
                            <h2>{AvibraEssential.subHeading}</h2>
                        </div>
                        <div className='memberships-benefits'>
                           
                            {EssentialList.map((item,index)=>{
                              return  <div className='membership-benefit-item' key={index}>
                                        <h4>{item.heading}</h4>
                                        <p>{item.description}</p>
                                      </div>
                            })}
                           
                            {/* <div className='bottom-button'>
                                <a href={AvibraEssential.btnLink} class="btn-class" target='_blank'>{AvibraEssential.btnText}</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <AllwellFooter />
        </section>
    );
}

export default Membership