import React from 'react';
import Accordian from './Accordian';
import HomePageCards from './HomePageCards';
import Navbar from './Navbar';
// import CardsBottomSection from './CardsBottomSection'
import AllwellFooter from './AllwellFooter';
import { infoData } from '../constant';

const HomePage = () => {
    return (
        <section className="section-home">
            <div className="container">
                <Navbar className="modify-nav"/>
                <HomePageCards />
                <div className="bottom-heading">
                    <p className="middle-content">{infoData.heading}</p>
                    <p>{infoData.text}</p>
                </div>
                <Accordian />
                {/* <CardsBottomSection /> */}
            </div>
            <AllwellFooter />
        </section>
    );
}

export default HomePage;
