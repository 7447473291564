import React,{useState} from 'react'
import Navbar from "../components/Navbar"
import AllwellFooter from '../components/AllwellFooter';
import BenefitsData from "../constant/BenefitsData.json"
import Overview from '../components/Overview';
import BenefitsOverview from '../components/BenefitsOverview';

const Benefits = () => {
    const {BenefitsOverviewData={},ResourcesOverview={},ourDollarBenefitsTabList={}}=BenefitsData
    const [isOpen, setIsOpen] = useState(0);
    const tabButtonClicked = (index) => {
        setIsOpen(index)
    }
    return (

        <section className="section-home">
            <div className="container">
                <Navbar className="modify-nav" />
                <BenefitsOverview BenefitsOverviewData={BenefitsOverviewData}/>

                <div className="container">
                    <div className='benefits-resources-overview'>
                        <div class="section-title">
                            <h2>{ResourcesOverview.title}</h2>
                        </div>
                        <div className='section-content'>
                            <p class="section-subtitle">{ResourcesOverview.description}</p>
                        </div>
                       
                    </div>

                    <div className='membership-plans'>
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            {ourDollarBenefitsTabList.data.map((item,index)=>{
                            return <li class="nav-item" role="presentation" key={index}>
                                        <a class={`nav-link ${index==isOpen?' active':''}`}  onClick={()=>tabButtonClicked(index)} id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{item.title}</a>
                                    </li>
                            })}
                           
                        </ul>
                        <div class="tab-content" id="myTabContent">
                        {ourDollarBenefitsTabList.data.map((data,index)=>{
                              return  <div class={`tab-pane fade  ${index==isOpen?'active show':''}`} key={index} id="home" role="tabpanel" aria-labelledby="home-tab">
                              <div className='member-plan-details'>
                                {data.insurances.map((item,index)=>{
                                    return  <div className='member-plan-row' key={index}>
                                                <div className='row-left'>
                                                    <div className='member-plan-icon'>
                                                        <img src={item.icon} alt={item.name ? item.name.replace(/<[^>]*>?/gm, '') : ''} />
                                                    </div>
                                                    <div className='member-plan-text'>
                                                        <h5 dangerouslySetInnerHTML={ { __html: (item.name) } }></h5>
                                                    </div>
                                                </div>
                                                <div className='row-right'>
                                                    <div className='member-plan-price'>
                                                    {item.cost}
                                                    </div>
                                                </div>
                                            </div>
                                })}
                                  
                              </div>
                          </div>
                        })}

                            {/* <div className='benefits-bottom-button'>
                                <a href={ourDollarBenefitsTabList.button.action} class="benefits-btn-class" target='_blank'>{ourDollarBenefitsTabList.button.text}</a>
                            </div> */}
                        </div>
                       
                    </div>
                </div>
            </div>
            <AllwellFooter />
        </section>
    );
}

export default Benefits