import React from 'react';

const CardComponent = (props) => {
    return(
        <div className="card">
            <div className="card-image">
                <figure className="image is-4by3">
                    <div className="card-data">
                        <img src={props.data.image} alt="Placeholder"/>
                    </div>
                    <div className="card-hover">
                        <span className="is-size-3">{props.data.heading}</span>
                        <p>{props.data.text}</p>    
                    </div>
                </figure>
            </div>
        </div>
    )}

export default CardComponent;
